/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: lightcyan #333;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: darkgrey;
  }

  *::-webkit-scrollbar-thumb {
    background-color: lightcyan;
    border-radius: 1em;
    border: 0.5em solid #333;
  }