
    .navPanel {
        clear:both;

        @include media-breakpoint-down(md) {
            display: none;
        }
        @include media-breakpoint-up(lg) {
            padding-top:  $medium-spacing;
            display: flex;
            flex-basis: auto;
            flex-flow: row;
            margin-top: 0;
            padding-top: $large-spacing;

        }
        p{
            &.intro {
                font-size: $large-text;
                line-height: $large-text;
            }
            &.preCta {

                @include media-breakpoint-down(sm) {
                    width: 33%;
                    text-align: right;
                    margin-right: 1em;
                    order: 2;
                }
            }
        }
        ul {
            @include media-breakpoint-down(sm) {
                width: 67%;
                order:1;
                margin-top: 0;
            }

            li {

                font-size: $small-text;
                background-color: rgba(136, 136, 136, 0.461);
                padding: $xsmall-spacing $small-spacing;
                margin-bottom: $small-spacing;
                font-weight: 600;
                border-left: $small-spacing rgba(158, 152, 152, 0.461) solid;

                @include media-breakpoint-up(md) {
                    font-size: $ml-text;
                }
            }
            li {

                a {
                    display: block;
                    text-decoration: none;
                    transition: all ease .25s;
                    font-weight: bold;

                    &:hover {
                        .home & {
                            color: var(--tint-color);
                        }
                    }
                }

                &:hover {
                    border-left: $small-spacing cyan solid;
                    .home & {
                        border-left: $small-spacing $homeTint solid;
                    }

                    .agency & {
                        border-left: $small-spacing $agencyTint solid;
                    }
                    .business & {
                        border-left: $small-spacing $businessTint solid; 
                    }
                    .startup & {
                        border-left: $small-spacing $startUpTint solid; 
                    }
                    .projects & {
                        border-left: $small-spacing $projectsTint solid; 
                    }
                    .solutions & {
                        border-left: $small-spacing $solutionsTint solid; 
                    }
                }
            }
        }
    }