.svg, .logoImage {
    display: block;
    width: 6em;
    height: 6em;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    path { fill: currentColor; }
    @include media-breakpoint-up(md) {
        width: 10em;
        height: 10em;
    }
}

// svg {
//     path { fill: currentColor; }
// }
.oc-logo {
    background-image: url(../svg/oc-simple.svg);
    &.enlarge {
        width: 30em;
        height: 30em;
        margin: 1em auto;
        @include media-breakpoint-down(sm) {
            width: 5em;
            height: 5em;
        }
    }
}

.oc-logo-black {
    background-image: url(../svg/oc-simple-blk.svg);
    &.enlarge {
        width: 30em;
        height: 30em;
        margin: 1em auto;
        @include media-breakpoint-down(sm) {
            width: 3em;
            height: 3em;
        }
    }}

.startupImage {
    background-image: url(../svg/noun-start-up-2984047.svg);
}
.mvpImage {
    background-image: url(../svg/noun-minimum-viable-product-286705.svg);
}
.auditImage {
    background-image: url(../svg/noun-audit-5024026.svg);

}
.auditImage2 {
    background-image: url(../svg/noun-audit-5032764.svg);

}
.smeImage {
    background-image: url(../svg/noun-sme-2347831.svg);
}
.agencyImage {
    background-image: url(../svg/agency.svg);
}

.machineLearning {
    background-image: url(../svg/mlIcon_1.svg);
}

.generation {
    background-image: url(../svg/noun-generation-1401168.svg);

}

.device {
    background-image: url(../svg/noun-devices-1306297.svg);
}
.contactImage {
    background-image: url(../svg/noun-contact-1906240.svg);
}
.cloud {
    background-image: url(../svg/noun-cloud-1007748.svg);
}
.autonomous {
    background-image: url(../svg/noun-autonomous-4700098.svg);
}
.challenges {
    background-image: url(../svg/noun-challenge-4558805.svg);
}
.example {
    background-image: url(../svg/noun-exampleexample-instance-lesson-case-precedent-doub-3858138.svg);
}
.creative {
    background-image: url(../svg/noun-creative-2127630.svg);
}
.stage {
    background-image: url(../svg/stage.svg);
}
.review {
    background-image: url(../svg/review.svg);
}
.whatsapp {
    background-image: url(../svg/WhatsAppButtonGreenLarge.svg);
    display: block;
    width: 13em;
    background-repeat: no-repeat;
    height: 3em;
    text-indent: -3000px;
}
.tintWhite {
    filter: invert(100%);
}