.page {

    padding-top: 6em;

    @include media-breakpoint-up(lg) {
        padding-top:0;
    }

    .svgBullet {
        display: block;
        width: 4em;
        height: 4em;
        background-size: contain;
        background-repeat: no-repeat;
        float: left;
        margin-top: -1em;
        margin-left: -1em;
        margin-right: 2em;
        margin-bottom: 2em;

        @include media-breakpoint-up(sm) {
            width: 5em;
            height: 5em;
        }
    }

    &.home {

        // .oc-logo-black {
        //     width: 6em;
        //     height: 6em;
        //     margin: 1em auto;
        //     @include media-breakpoint-up(md) {
        //      width: 13em;
        //      height: 13em;
        //     }
        // }
        .pagePanel {
            background-color: #52A9F912;
            gap: 2em;

            &--section {
                @include media-breakpoint-down(md) {
                    margin-bottom: $large-spacing;
                    .homeNav {
                        display: block;
                        margin-top: 0;
                    }

                    p {
                        margin: 0 0 1em  0;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            p {
                color: #fff;
            }

            h2,h3,.preCta {
                color: #fff;
            }
        }
    }

    &.startup, &.agency {
        .pagePanel {
            //background-color: #00b2ff3b;//#00b2ff85;
            ol li::before {
                color: $startUpTint;
            }
            @include media-breakpoint-up(sm) {
                background-color: #00b2ff3b
            }
        }
    }
    &.solutions {
        .pagePanel {
            background-color:rgba(255, 255, 255, 0.9);
            ol li::before {
                color: $solutionsTint;
            }
            // @include media-breakpoint-up(sm) {
            //     background-color: #00b2ff3b
            // }
            .preCta{
                color: $solutionsTint;
            }
            p {
                color:rgb(4, 16, 34);
                line-height: 1.25;;
            }
            h2,h3 {
                font-weight: bold;
                color: $solutionsTint2;
            }
            .cta {
                background-color: $solutionsTint2;
                color: white;
                a {
                    color: white;
                }
            }
        }
    }
    &.startup {
        .pagePanel {
            color: black;
            background-color: rgba(187, 203, 226, 0.141176);

            .cta {
                background-color: $startUpTint;
                color: black;
            }
            .accordion {
                button {
                    color: $startUpTint;
                }
                .accordion-body, .accordion-body  ul li{
                    color: black;
                }
            }
        }
    }
    &.agency {
        .pagePanel {
            color: black;
            background-color: #f3f3f3d6;
            ol li::before {
                color: $agencyTint;
            }

            .accordion {
                button {
                    color: $agencyTint;
                }
                .accordion-body, .accordion-body  ul li{
                    color: black;
                }
            }

            .cta a{
                color: white;
            }
        }
    }

    &.business {

        .pagePanel {
            background-color: rgba(255, 255, 255, 0.536);
            color: black;

            .accordion {
                h4,h2 button, ul li {
                    color: white;
                }
            }
            .cta a {
                color: white;
            }
        }
    }

    &.contact {
        color: $homeTint;
 

        .contactImage {
            background-image: url(../svg/noun-contact-1906240.svg);
        }

        .calendly-inline-widget {
            height: 700px !important;
        }
    }
    &.projects {
        .pagePanel {
            background-color: #0dcaf01a;
            color: black;

            .cta {
                background-color: $projectsTint;
                a { 
                    color: black;
                    text-decoration: none;
                    &:hover {
                        color: black;
                    }
                  }


            }
            h2,h3,.preCta {
                color: $projectsTint;
            }
        }
    }

    &.credits {
        h2 {
            margin-top: $large-spacing;
            margin-bottom: $medium-spacing;
        }
    }
}