.bottomNav {
    margin-top: $xlarge-spacing;

    @include media-breakpoint-down(sm) {
        bottom: 2em;
        position: relative;
    }
    ul {
        justify-content: center;
        li {


            a.nav-link {
                @include media-breakpoint-down(sm) {
                    padding: $xsmall-spacing;
                    margin: 0;
                }
                .homeTint & {
                    color: white;
                }
            }

        }
    }
}