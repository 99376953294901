.home {
    padding-top: 1em;
    @include media-breakpoint-up(sm) {
        padding-top: 6em;
    }

    h1 {
        text-align: center;
        // margin-bottom: $medium-spacing;
    }

    .pagePanel {
        text-align: center;

        &--section {
            h1 {margin: 1em 0};
            .homeNav {
                margin-top: $large-spacing;
                .logoImage {
                       // margin: 0 0 1em 0 !important;
                        height: 100%;
                        width: 4em;
                        min-height: 4em;
                        @include media-breakpoint-up(md) {
                            height: 5em;
                            width: 5em;
                        }
                        @include media-breakpoint-up(xl) {
                            height: 9em;
                            width: 8em;
                        }
                    }
                    .logoImage + h3 {
                      margin-top:0.5em;

                    }
                ul {
            
                    &.multi {
                        display: flex;
                        justify-content: space-between;
                    }
                    li {
                        color: white;
                        font-weight: bold;
                        text-align: center;
                        list-style-type: none !important;
                        padding: $small-spacing;
                        border-radius: 0.5em;
            
                        h2 {
                            font-size:  $medium-text;
                            font-weight: bold;
                            text-align: center;
                            margin-bottom: 0;
            
                            @include media-breakpoint-up(md) {
                                font-size: $xlarge-text;
                                margin-bottom: $small-spacing;
                            }
                        }
                        h3 {
                            font-size:  $medium-text;
                            font-weight: bold;
                            text-align: center;
                            margin-bottom: 0;
                        }
                        a {
                            transition:cubic-bezier(1, 0, 0, 1) color;
                            display: block;
                            font-weight: bold;
                            opacity: 1;
                            transition: 0.3s;
                            text-decoration: none;
                            color: white;                
                            @include media-breakpoint-down(sm) {
                                opacity: 1;
            
                            }
                        }
                        a:hover {
                            opacity: 1;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }



}


.homeTint {
    color: white !important;


}