

.accordion {

    @include media-breakpoint-down(sm) {
        margin: 0 0 $medium-spacing 0;
    }
    .accordion-button {
        background-color: transparent;
        border-left-color: black;

        .home & {
            border-left-color: $homeTint;
        }
        .agency & {
            border-left-color: $agencyTint;
        }
        .projects & {
            border-left-color: $projectsTint;
        }
        .startup & {
            border-left-color: $startUpTint;
        }
        .business & {
            border-left-color: $businessTint;
        }

        border-left-style: solid;
        border-left-width: $small-spacing;
        padding: $xsmall-spacing;
        font-size: 75%;
        width: 60%;
        @include media-breakpoint-down(sm) {
            border-left-width: $xsmall-spacing;
            font-size: 50%;
            width: 100%;
        }

        &::after {
            content: "+";
            background-image: none;
            font-size: 1.5em;
            line-height: 0.5em;
        }
        &:not(.collapsed)::after {
            content: "-";
            transform: none;
            font-size: 1.5em;
            line-height: 0.5em;
        }
    }
    .accordion-body {
        ul {
            margin: $large-spacing 0;
        }
        ul li, ol li {
            font-size: $medium-text;
            line-height: $medium-text;
            list-style: square;
            color: #222;
            margin-left: $large-spacing;
            @include media-breakpoint-down(sm) {
                font-size: 100%;
            }
        }
 
    }
}