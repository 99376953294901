.topNav {
    @include media-breakpoint-down(lg) {
        position: fixed;
        left: 0;
        z-index: 1;
        height: 5em;
        width: 100%;
        background: white;
        padding: 1.25em
    }

    .menu {
        transition: all 0.75s;

        &.closed {
            @include media-breakpoint-down(md) {
                opacity: 0;
                height: 0vh;
                visibility: hidden;
            }
        }
        &.open {
            @include media-breakpoint-down(md) {
                opacity: 1;
                visibility: visible;
                }
        }
    }
    .svg, .logoImage {
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        background-size: contain;
        background-repeat: no-repeat;
        @include media-breakpoint-up(sm) {

            width: 1.45em;
            height: 1.45em;
        }
    }
    h1,h2 {
        color: black;
        font-size: $large-text;
        font-weight: bold;
        padding: 0;
        margin: 0;
        line-height: 1.7em;
        margin-top: 0.25em;


        @include media-breakpoint-up(md) {
            .homeTint & {
                color: white;
            }
        }
    }

    .nav {

        @include media-breakpoint-down(md){
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            right: 0;
            top: 70px;
            width: 100%;
            background: white;
            z-index: 10;
            position: absolute;
            padding: $medium-spacing;
            height: 100vh;
        }
        @include media-breakpoint-up(md){
            align-items: center;
            height: 100%;
        }
        .nav-item {
            margin: 0;
            width: 100%;
            @include media-breakpoint-up(md) {
                width: auto;
            }

            .nav-link {
                color: black;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0.25em 0;
                text-align: center;
                margin-right: $xsmall-spacing;
                width: 100%;
                font-size: $large-text;
                //text-indent: -9000px;
                .svg {
                    display: inline-block;
                    margin-right: $xsmall-spacing;
                    border:3px black solid;
                    border-radius: 3em;
                }
                @include media-breakpoint-up(md) {
                    width: auto;
                    font-size: $medium-text;

                    .svg {
                        border:3px transparent solid;
                        margin-left: $xxsmall-spacing;
                        margin-right: 0;
                    }


                }
                .homeTint & {
                    color: white;
                }


            }
        }


    }
    .hamburger {
        // removes default border on button element
        border: 0;
        height: 40px;
        width: 40px;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: #000;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        position: absolute;
        top: 1em;
        right: 1em;
        display: none;
        @include media-breakpoint-down(md) { 
            display: block;
        }
        &:hover {
            background-color: $homeTint;
          }
      }

    nav {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: inline-flex;
            justify-content: space-around;
            line-height: $large-text;
            li {
                color: #222;
                text-align: center;
                margin: 0;
                .homeTint & {
                    color: white;

                }
            }
            a  {
                color: black;
                 opacity: 0.5;
                 transition: 0.3s;
            }
            a:hover {
                 opacity: 1;
            }
        }

    }
}