.cta {
    text-decoration: none;
    &:hover{
        text-decoration: none;
        transition: all 0.2s linear;
        filter: invert(1);

    }
    a {
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}