@import 'spacing'; 


//text sizes
$small-text: 0.75rem;
$medium-text: 1.25rem;
$ml-text: 1.5rem;
$large-text: 1.75rem;
$xlarge-text: 2rem;
$xxlarge-text: 2.2rem;
$xxxlarge-text: 3rem;

.small-text{
    font-size: $small-text;
}
.medium-text{
    font-size: $medium-text;
}
.ml-text{
    font-size: $ml-text;
}
.large-text{
    font-size: $large-text;
}
.xlarge-text{
    font-size: $xlarge-text;
}
.xxlarge-text{
    font-size: $xxlarge-text;
}
.xxxlarge-text{
    font-size: $xxxlarge-text;
}
h1,h2, h3, h4 ,h5 ,h6 {
    font-family: new-order, sans-serif;
    font-style: normal;
}
h1 {
    font-size: $xxlarge-text;
    font-weight: bold;
    line-height: $xxlarge-text;
    &.logo {
        font-family: new-order, sans-serif;
    }
}
h2 {
    font-size: $xlarge-text;
    line-height: $xlarge-text;
    &.logo {
        font-family: new-order, sans-serif;
        font-weight: bold;
    }
}
h3 {
    font-size: $medium-text;
    margin-bottom: $medium-spacing;
    line-height: $large-text;
    @include media-breakpoint-up(sm) {
        font-size: $large-text;
    }
}

h4, h5,p,span, ul li, ol li, label, input, button {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: $small-spacing;
    font-size: $medium-text;
}
p {line-height: 1.25;}

input, textarea {
    display: block;
    width: 100%;
    padding: $small-spacing;
}
textarea {
    height: 6em;
    margin-bottom: 1em;
}
button[type="submit"] {
    color: white;
    background-color: #8820ff;
    padding: 0.5em;
    border-radius: 0.25em;
    display: inline-block;
}