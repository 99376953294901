


.sidePanel {
   // @include media-breakpoint-dn(sm){
        align-items: center;
        justify-content: space-between;
        display: flex;
        margin-bottom: $medium-spacing;
        height: 90%;
        padding: 0 1.25em;
   // }
    @include media-breakpoint-up(md)    {
        display: block;
        padding: 0;
        margin-bottom: $large-spacing;
    }


    h1 {
        font-weight: bold;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: normal;
        font-size: $xxxlarge-text;
        @include media-breakpoint-up(sm) {
           padding-top:10px;
           text-align: center;
           display: block;
        }

        .home & {

            font-size: 1.75rem;
        }

        .agency & {
            color: $agencyTint;
        }

        .projects & {
            color: $projectsTint;
        }

        .business & {
            color: $businessTint;
            font-size: 1.5rem;
        }
        .startup & {
            color: $startUpTint;
        }
        .contact & {
            color: $homeTint;
        }
        .solutions & {
            color: $solutionsTint;
        }
    }
    h2 {
        margin-bottom: $medium-spacing;
        @include media-breakpoint-up(sm) {
            padding-top: $large-spacing;
            margin-bottom: 0;

        }
    }
    ul {
        display: none;
        @include media-breakpoint-up(sm) {
            display: block;
            margin-top: $large-spacing;
        }
    }
    .logoImage {

        margin-right: 1em;

        @include media-breakpoint-up(md) {
            margin-right: 0;
            margin-top: $medium-spacing;
            height: 11em;
            width: 100%;
        }
        @include media-breakpoint-up(xl) {
            width: 100%;
            height: 22em;
            float: left;
        }
    }
}