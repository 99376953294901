.pagePanel {
    border-radius: 15px 0 0 54px;
    padding: $large-spacing $large-spacing $xlarge-spacing $large-spacing;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    .home & {
        padding: $large-spacing;
    }

    &--section {
        margin: 0 0 $medium-spacing;
        @include media-breakpoint-up(sm) {
            margin: 0 0 $xxlarge-spacing;
        }
        .home & {
            margin: 0 0 $large-spacing;
        }

        h2,h3 {
            font-weight: bold;

        }
        h3 {
            margin-bottom:0;
        }
        h3 {
            .home & {
                color: $homeTint;
            }
            .contact & {
                color: $homeTint;
            }
            .business & {
                color: $businessTint;
            }
            .agency & {
                color: $agencyTint;
            }
            .startup & {
                color: $startUpTint;
            }
            .project & {
                color: $projectsTint;
            }
            .solutions & {
                color: $solutionsTint;
            }
        }

        &.logos {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap:5px; /* even spacing using grid-gap, You can use percentage values if you want*/
            padding:5px;

            img {
                padding: 5px;
                width: 100%;
                margin: auto 0;
                border-radius: 5px;
                background: white;
            }
        }
        &.tech {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap:5px; /* even spacing using grid-gap, You can use percentage values if you want*/
            padding:5px;

            .techelement {
                img {
                    width: 100%;
                }
                p {
                    font-size: 0.75em;
                    text-align: center;
                }
            }



        }
    }

    @include media-breakpoint-up(xl) {
        overflow-y: auto;
        overflow-x: hidden;
        padding: $xxlarge-spacing;
     }
    ul li {
        list-style-type: none;

    }
    p,ul,li{
        &.preCta {
            font-weight: bold;
            font-size: $medium-text;
            //line-height: $large-text;
            list-style: none;
            margin-left: 0;
        }
    }
    .svg,.logoImage {
        clear:both;

        float: right;
        margin-left: 3vw;

        .home & {
            float: none;
            margin: 0 auto !important;
        }


    }
    .solutions & {
        .logoImage.floatLeft {
            clear:both;
            float: left;
            margin-left: 0;
            margin-right: 3vw;
            margin-bottom: 3vw;
        }
    }

    .intro {
        padding: $large-spacing;
        border-radius: $small-spacing;
        margin-bottom: $large-spacing;

        p {
            font-size: $medium-text;
            line-height: $large-spacing;
        }
    }
    .preAccordion {
        @include media-breakpoint-up(sm) {
            font-size: $ml-text;
            line-height: $ml-text;
            padding: $small-spacing 0 $medium-spacing;

    
        }
    }

    .center {
        text-align: center;
    }
    .tint {
        .home & {
            color: white;
            background-color: $homeTint;
            padding: 0.5em;
            border-radius: 0.25em;
            display: inline-block;
        }
    }
    li.caseStudy {
        padding: 2em;
        background: white;
        color: #111;
        position: relative;
        margin: 2em 0em 4em 0;
        min-height: 7em;
    }

    .caseStudyTitle {
        position: absolute;
        top: -25px;
        right: 0;
        background: black;
        color: white;
        padding: 0.25em;
    }
    .cta {
        margin-bottom: $xlarge-spacing;
    }
    h2 {
        margin-bottom: $small-spacing;
    }

    h2:nth-of-type(n+2) { 
        margin-top: 0;
        @include media-breakpoint-up(sm) {
            margin-top: $large-spacing;
        }
    }

    p {
        color: $black;
    }

    ol {list-style: none; counter-reset: li}
    ol li {counter-increment: li}
    ol li::before {content: counter(li); 
        font-weight: bold;
        color: $startUpTint;
        display: inline-block; 
        width: 1em; margin-left: -1.5em;
        margin-right: 0.5em; text-align: right; direction: rtl}

        p.intro.preCta {
            a {
                padding: $xsmall-spacing;
                background-color: white;
                color: rgb(17, 22, 97);
                border-radius: $small-spacing;
            }
            margin-bottom: $medium-spacing;
        }


        .cta {
            font-weight: bold;
            border-radius: 0.25em;
            background: black;
            padding: 0.5em;
            display: inline-block;
        }
}