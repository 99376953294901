// spacing
$xxsmall-spacing: 0.15em;
$xsmall-spacing: 0.25em;
$small-spacing: 0.5em;
$medium-spacing: 1em;
$large-spacing: 1.5em;
$xlarge-spacing: 3em;
$xxlarge-spacing: 6em;
$xxxlarge-spacing: 8em;

//colours;
